import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toolbar, CssBaseline } from '@mui/material';
import { HuanbjtToolbar } from './components/HToolbar';
import HomePage from './pages/HomePage';
import DetailPage from './pages/DetailPage';
import DragAndDropOverlay from './components/DragAndDropOverlay';
import GlobalPasteHandler from './components/GlobalPasteHandler';

import './css/App.css';

function App() {
  return (
    <Router>
      <DragAndDropOverlay />
      <GlobalPasteHandler />
      <HuanbjtToolbar />
      <Toolbar /> {/* Add this to account for the fixed AppBar height */}
      <CssBaseline />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/detail" element={<DetailPage />} />
      </Routes>
    </Router>
  );
}

export default App;
