import React from 'react';
import { Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import UploadBox from '../components/UploadBox';
import SampleStyle from '../components/SampleStyple';
import SlogoStyle from '../components/Slogo';
import '../css/HomePage.css';
import { HuanbjtFooter } from '../components/Footer';

const HomePage = () => {
  const navigate = useNavigate();
  const navigateToDetailPage = (imageFile, requested) => {
    // imageFile: 上传的图片
    // requested: 是否需要请求网络
    navigate('/detail', { state: { imageFile, requested } });
  }

  return (
    <div className="app-root">
      <Container maxWidth="lg" style={{ marginTop: '132px', textAlign: 'center' }}>
        <Grid container spacing={3} justifyContent="center" alignItems="center" marginTop='20px'>
          <SlogoStyle />
          <Grid item xs={12} md={6}>
            <UploadBox onImageChange={navigateToDetailPage} />
          </Grid>
        </Grid>
        <SampleStyle onImageChange={navigateToDetailPage}/>
      </Container>
      <HuanbjtFooter />
    </div>
  );
};

export default HomePage;
