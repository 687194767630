import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { SwatchesPicker } from 'react-color';

const ColorPickerModal = ({ open = false, onClose = () => {}, onSelectColor = () => {} }) => {
  const [selectedColor, setSelectedColor] = useState('#ffffff');

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
  };

  const handleSelect = () => {
    onSelectColor(selectedColor);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>选择背景颜色</DialogTitle>
      <DialogContent>
        <SwatchesPicker onChangeComplete={handleColorChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>取消</Button>
        <Button onClick={handleSelect} color="primary">完成</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColorPickerModal;
