import React, { useState } from 'react';
import { Box, Button, Toolbar, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DEFAULT_IMAGE, DETAIL_PAGE_BUTTON_ADD_IMAGE } from '../utils/Constant';
import '../css/HBottomBar.css';

export const HuanbjtBottombar = ({upload, updatePreviewImage}) => {
  const [images, setImages] = useState([]);

  const handleUploadClick = () => {
    document.getElementById('fileInput').click();
  };

  // 删除该图片, 显示index=0的图片预览/ 或者显示default
  const handleRemoveImage = (index) => {
    const bImagesLength = images.length;
    setImages(images.filter((_, i) => i !== index));

    const showIndex = bImagesLength === 1 ? -1 : 0;
    UpdateSelectedImage(showIndex);
  };

  // 点击下列图片, 更新 DetailPage 上面的预览
  const UpdateSelectedImage = (index) => {
    const image = getValidPreviewImage(index);
    updatePreviewImage(image);
  }

  const getValidPreviewImage = (index) => {
    if (index < 0) {
      return DEFAULT_IMAGE;
    }

    const image = images[index];
    return image;
  }

  const UploadImageOnChange = (e) => {
    const newImage = e.target.files[0];
    if (newImage) {
      if (images.length < 9) {
        // const newImage = 'https://via.placeholder.com/150'; // Replace this with your image URL

        const reader = new FileReader();
        reader.onloadend = () => {
          setImages([...images, reader.result]); 
        };
        reader.readAsDataURL(newImage);

        // 上传图片
        upload(newImage);
      } else {
        alert('上传的图片已经到达最大值!')
      }
    }
  }

  return (
    <Toolbar style={{ backgroundColor: 'transparent', marginTop: '-44px' }}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="84px"
        bgcolor="transparent"
        textAlign="center"
      >
        <Button 
          sx={{
            backgroundColor: 'rgb(214,227,255)',
            width: '84px',
            height: '84px',
            borderRadius: '12px'
          }} 
          onClick={handleUploadClick}
        > 
          {DETAIL_PAGE_BUTTON_ADD_IMAGE}
        </Button>
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          onClick={(event) => {event.target.value = null}}
          onInput={UploadImageOnChange}
          style={{ display: 'none' }}
        />
        <div id="imageContainer">
          {
            images.map((image, index) => 
              (
                <Box key={index} className="image-container">
                  <img key={index} src={image} alt={`Added ${index}` } style={{ maxWidth: '84px', maxHeight: '84px', height: '84px', marginLeft: '10px', marginTop: '6px', cursor: 'pointer' }} 
                    onClick={() => UpdateSelectedImage(index)} 
                  />
                  <IconButton
                    className="delete-icon"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )
            )
          }
        </div>
      </Box>
    </Toolbar>
  );
};