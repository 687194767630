import React from 'react';
import { Typography, Grid } from '@mui/material';

const SlogoStyle = () => {
    return (
      <Grid item xs={12} md={6}>
          <Typography variant="h2" style={{ marginTop: '20px', fontWeight: '600', color: 'rgb(69 69 69)' }}>
            图片背景替换
          </Typography>
          <Typography variant="h6" style={{ marginTop: '10px', color: 'gray' }}>
            100% 全自动且 <span style={{ color: 'orange', fontWeight: 'bold' }}>免费</span>
          </Typography>
      </Grid>
    );
};

export default SlogoStyle;